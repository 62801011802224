import { useRouter } from 'next/router'
import { LoginModalBrowse } from 'components/LoginModalBrowse'
import { useSession } from '@smu-chile/pkg-unimarc-hooks'
import { useEffect, useState } from 'react'

export const AuthWrapper = () => {
  const router = useRouter()
  const session = useSession()
  const [isLoggedIn, setIsLoggedIn] = useState(true)

  const handleLoginModalClose = () => {
    if (session.isLoggedIn) {
      const { pathname, query } = router
      delete router.query.login
      router.replace({ pathname, query }, undefined, { shallow: true })
    } else {
      router.replace(process.env.NEXT_PUBLIC_HOMEURL || '/')
    }
  }

  useEffect(() => {
    if (!session?.isLoggedIn && !session?.isLoading) {
      setIsLoggedIn(false)
    }
  }, [session?.isLoggedIn, session?.isLoading])

  return (
    <>
      {!isLoggedIn && (
        <LoginModalBrowse
          isOpen={true}
          onClose={handleLoginModalClose}
          router={router}
        />
      )}
    </>
  )
}
