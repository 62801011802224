import { useEffect, useRef, useState } from 'react'
import { NextRouter } from 'next/router'
import {
  LoginFormStepts,
  LoginFormValuesV2,
  LoginModalProps,
  LoginModalV2
} from '@smu-chile/pkg-unimarc-components'
import {
  cleanRut,
  formatRut,
  sanitizeRut,
  validateRut
} from '@smu-chile/pkg-unimarc-components/utils/formatRut'
import {
  getSsoForgotPasswordUrl,
  TagLoginRef,
  tagLoginSuccess,
  loginV2,
  tagLoginInteraction,
  tagLoginErrorCredentials,
  tagLoginErrorUnknown,
  useMobile,
  postAuthFirebaseRegister,
  useFirebase,
  getRegisterUrl,
  getDevice,
  postLoginV2,
  postAuthFirebaseLoginV2,
  postAuthFirebaseRegisterRutV2,
  postAuthFirebaseRegisterRutConfirmV2,
  postAuthRegister,
  sleep
} from '@smu-chile/pkg-unimarc-hooks'
import HttpError from '@smu-chile/pkg-unimarc-hooks/shared/errors/HttpError'
import {
  ErrorTypes,
  LoginFormProps,
  LoginFormSuccess,
  LoginFormV2Error
} from '@smu-chile/pkg-unimarc-components/shared/interfaces/ILoginModal'
import {
  getTyCAndPP,
  handleLoginErrors,
  handleOTPErrors,
  loginTriesAtempts
} from 'shared/helpers'
import { InnerModal } from './InnerModal'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

interface LoginModalBrowseProps extends LoginModalProps {
  tagLoginRef?: TagLoginRef
  router?: NextRouter
}

export const LoginModalBrowse = ({
  tagLoginRef,
  isOpen,
  onClose,
  router
}: LoginModalBrowseProps): React.ReactElement => {
  const loginTries = useRef(0)
  const loginLastTry = useRef(new Date())
  const forgotPasswordURL = getSsoForgotPasswordUrl()
  const [checkingRut, setCheckingRut] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [showInnerModal, setShowInnerModal] = useState(false)
  const [TyCndPPData, setTyCAndPPData] = useState({
    ppRichtextData: null,
    tycRichtextData: null
  })
  const [innerModalContent, setInnerModalContent] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const [formStep, setFormStep] = useState<LoginFormStepts>('login')
  const [formValues, setFormValues] = useState<LoginFormValuesV2>({
    rut: '',
    password: '',
    tyc: false,
    phone: '',
    firebaseIdToken: '',
    deliveryMedium: '',
    destination: '',
    platform: undefined
  })
  const [formState, setFormState] = useState<LoginFormProps>({
    dirty: { password: false, rut: false },
    errors: { rut: undefined }
  })

  const [success, setSuccess] = useState<LoginFormSuccess>({})

  const { isMobile } = useMobile()
  const { loginWithPopup, getUserData, getToken } = useFirebase()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleToast = async (timeout = false) => {
    setShowToast(true)

    if (timeout) {
      await sleep(5000)
      setShowToast(false)
    }
  }

  const setError = (field: keyof LoginFormV2Error, value: ErrorTypes) => {
    setFormState((prev) => {
      return {
        ...prev,
        errors: {
          ...prev.errors,
          [field]: value
        }
      }
    })
  }

  const resetFormState = () => {
    const newFormState = {
      dirty: {},
      errors: {}
    }
    setFormState(newFormState)
    return newFormState
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!formState.dirty[e.target.name]) {
      setFormState({
        ...formState,
        dirty: {
          ...formState.dirty,
          [e.target.name]: true
        }
      })
    }
    if (e.target.name === 'rut') {
      if (cleanRut(e.target.value).length < 10) {
        setFormValues(() => {
          return {
            ...formValues,
            [e.target.name]: formatRut(sanitizeRut(e.target.value))
          }
        })
      }
    } else if (e.target.name === 'phone') {
      if (e.target.value.length <= 8) {
        formState?.errors?.phone && setError('phone', undefined)
        setFormValues(() => {
          return {
            ...formValues,
            [e.target.name]: e.target.value
          }
        })
      }
    } else {
      if (formState.errors.rut === 'atempts') {
        setFormState({
          ...formState,
          errors: { rut: undefined }
        })
      }
      setFormValues(() => {
        return {
          ...formValues,
          [e.target.name]: e.target.value
        }
      })
    }
  }

  const handlePressReturn = (e?: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !submitDisabled && !isLoading) {
      const element = e.target as HTMLInputElement
      setShowToast(false)
      if (element.id === 'rut' && formState.errors.rut === 'registration') {
        window.location.href = `${getRegisterUrl()}?rut=${cleanRut(
          formValues.rut
        )}`
      } else {
        handleSubmitLogin()
      }
    }
  }

  const handleClose = () => {
    setShowToast(false)
    setIsLoading(false)
    setFormValues({
      rut: '',
      password: '',
      phone: '',
      name: '',
      platform: undefined
    })
    resetFormState()
    setSubmitDisabled(true)
    onClose?.()
    setFormStep('login')
  }

  const handleClickBack = () => {
    if (formStep === 'otpMail' || formStep === 'otpPhone') {
      setFormValues({
        ...formValues,
        deliveryMedium: '',
        destination: ''
      })
      setIsLoading(false)
      setFormStep('link')
    } else if (formStep === 'link') {
      setFormValues({
        ...formValues,
        rut: '',
        tyc: false,
        deliveryMedium: '',
        destination: '',
        name: ''
      })
      setFormState({
        ...formState,
        dirty: {
          ...formState.dirty,
          rut: false
        },
        errors: {
          ...formState.errors,
          rut: undefined
        }
      })
      setIsLoading(false)
      setFormStep('registration')
    } else {
      setFormValues({
        rut: '',
        password: '',
        tyc: false,
        phone: '',
        firebaseIdToken: '',
        deliveryMedium: '',
        destination: '',
        name: ''
      })
      resetFormState()
      setIsLoading(false)
      setFormStep('login')
    }
  }

  const handleClickFirebase = async (platform?: 'apple' | 'google') => {
    setShowToast(false)
    setSubmitDisabled(true)
    setFormValues({
      rut: '',
      password: '',
      phone: '',
      platform
    })

    resetFormState()
    if (!TyCndPPData?.ppRichtextData || !TyCndPPData?.tycRichtextData) {
      const { ppRichtextData, tycRichtextData } = await getTyCAndPP()
      setTyCAndPPData({
        ppRichtextData,
        tycRichtextData
      })
    }
    try {
      await loginWithPopup(platform)
      setIsLoading(true)
      const token = await getToken()
      const loginResponse = await postAuthFirebaseLoginV2({
        firebaseIdToken: token
      })

      if (loginResponse?.error) {
        setIsLoading(false)
        const { email, name: fullName } = await getUserData()
        const name = fullName ? fullName.split(' ')[0] : null
        const lastname = fullName
          ? fullName.split(' ').slice(1).join(' ')
          : null
        if (token) {
          resetFormState()
          setFormValues({
            ...formValues,
            firebaseIdToken: token,
            email,
            name,
            lastname,
            platform
          })
          setFormStep('registration')
        }
      } else {
        const { pathname } = router
        const params = new URLSearchParams(window.location.search)
        params.delete('login')
        router.replace({ pathname, query: params.toString() }, undefined, {
          shallow: true
        })
        router.reload()
      }
    } catch (error) {
      setIsLoading(false)
      resetFormState()
      setFormValues({
        rut: '',
        password: '',
        phone: '',
        platform: null
      })
    }
  }

  const handleClickTyC = () => {
    if (
      formStep === 'registration' &&
      formState.errors.rut === 'registration' &&
      formValues.phone.length === 8 &&
      !formState.errors.phone
    ) {
      setFormValues({
        ...formValues,
        tyc: !formValues.tyc
      })
    }
  }

  const handleClickLink = () => {
    setFormStep('link')
  }

  const handleClickOTPMail = async () => {
    setIsLoading(true)
    setFormValues({
      ...formValues,
      deliveryMedium: 'EMAIL'
    })
    const response = await postAuthFirebaseRegisterRutV2({
      rut: formValues.rut,
      firebaseIdToken: formValues.firebaseIdToken,
      deliveryMedium: 'EMAIL'
    })
    if (!response.error) {
      setFormValues({
        ...formValues,
        destination: await response.codeDelivery.destination
      })
      setFormStep('otpMail')
      setIsLoading(false)
    }
  }

  const handleClickOTPPhone = async () => {
    setIsLoading(true)
    setFormValues({
      ...formValues,
      deliveryMedium: 'SMS'
    })
    const response = await postAuthFirebaseRegisterRutV2({
      rut: formValues.rut,
      firebaseIdToken: formValues.firebaseIdToken,
      deliveryMedium: 'SMS'
    })
    if (!response.error) {
      setFormValues({
        ...formValues,
        destination: await response.codeDelivery.destination
      })
      setFormStep('otpPhone')
      setIsLoading(false)
    }
  }

  const handleSubmitLogin = async () => {
    setIsLoading(true)
    if (formState.errors.rut === 'registration') {
      window.location.href = `${getRegisterUrl()}?rut=${cleanRut(
        formValues.rut
      )}`
    } else {
      resetFormState()
      setShowToast(false)
      try {
        loginTriesAtempts(loginTries, loginLastTry)

        loginLastTry.current = new Date()
        loginTries.current++

        const token = await executeRecaptcha('identity_lookup')
        tagLoginInteraction(tagLoginRef)
        const response = await postLoginV2({
          requestOptions: {
            headers: {
              'X-Google-Recaptcha-Response': token
            }
          },
          document: formValues.rut,
          password: formValues.password
        })

        if (tagLoginRef) tagLoginSuccess(tagLoginRef)
        await loginV2(response, router)
      } catch (error) {
        const enableTimeout = error?.['status'] !== 404
        handleLoginErrors(
          error,
          loginTries,
          setError,
          tagLoginRef,
          tagLoginErrorCredentials,
          tagLoginErrorUnknown
        )
        handleToast(enableTimeout)
        setIsLoading(false)
      }
    }
  }

  const handleClickFinishRegistration = async () => {
    setIsLoading(true)
    const { email } = await getUserData()
    const response = await postAuthFirebaseRegister({
      email,
      familyName: formValues?.lastname || 'Apellido',
      firebaseIdToken: formValues.firebaseIdToken,
      name: formValues?.name || 'Nombre',
      phone: `+569${formValues.phone}`,
      rut: formValues.rut
    })

    if (response.status === 200) router.reload()

    if (
      response?.data?.data?.error?.code === 'SSO_36' ||
      response?.data?.data?.error?.code === 'SSO_37'
    ) {
      setError('phone', 'link')
      setIsLoading(false)
      setFormValues({
        ...formValues,
        tyc: false
      })
    } else if (
      response?.data?.data?.error?.code === 'SSO_23' ||
      response?.data?.data?.error?.code === 'SSO_33'
    ) {
      setIsLoading(false)
    }
  }

  const handleSubmitOTP = async (code?: string) => {
    try {
      await postAuthFirebaseRegisterRutConfirmV2({
        code,
        firebaseIdToken: formValues.firebaseIdToken
      })

      router.reload()
    } catch (error) {
      handleOTPErrors(error.data.code, setError)
      handleToast()
    }
  }

  const handleResendOTP = async () => {
    try {
      const response = await postAuthFirebaseRegisterRutV2({
        rut: formValues.rut,
        firebaseIdToken: formValues.firebaseIdToken,
        deliveryMedium: formValues.deliveryMedium
      })

      if (response.error) {
        throw new HttpError(response.error, 500, {
          code: `${response.code}: ${response.error}`
        })
      }

      router.reload()
    } catch (error) {
      handleOTPErrors(error.data.code, setError)
      handleToast()
    }
  }

  const handleOpenTyC = () => {
    setInnerModalContent('tyc')
    setShowInnerModal(true)
  }

  const handleOpenPP = () => {
    setInnerModalContent('pp')
    setShowInnerModal(true)
  }

  const handleCloseInnerModal = () => {
    setInnerModalContent(null)
    setShowInnerModal(false)
  }

  const handleClickGuest = () => {
    router.push(process.env.NEXT_PUBLIC_HOMEURL)
  }

  const handleRutVerification = (rutExists?: boolean, rut?: string) => {
    setFormValues((prev) => {
      const rutValid = cleanRut(prev.rut).length > 7 && validateRut(prev.rut)
      const rutIsSame = prev.rut === rut

      if (rutValid && rutIsSame) {
        if (rutExists) {
          setError('rut', formStep === 'registration' ? 'link' : undefined)
          setShowPassword(true)
        } else {
          setError('rut', 'registration')
          setShowPassword(false)
          setSuccess({ ...success, rut: false })
          handleToast()
        }
      }

      return {
        ...prev,
        password: ''
      }
    })
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (cleanRut(formValues.rut).length > 7 && validateRut(formValues.rut)) {
        setCheckingRut(true)
        await postAuthRegister({ rut: formValues.rut }).then((response) => {
          const rutExists = response.data.data.error.code === 'SSO_31'
          handleRutVerification(rutExists, formValues.rut)
        })
        setCheckingRut(false)
      } else {
        setCheckingRut(false)
        setShowPassword(false)
      }
    }, 0)

    return () => {
      return clearTimeout(delayDebounceFn)
    }
  }, [formValues.rut])

  useEffect(() => {
    setShowToast(false)
    setSuccess({ ...success, rut: false })

    const isDirty = formState.dirty.rut
    const isEmpty = formValues.rut.length < 1
    const isMinimum = formValues.rut.length > 10
    const isValid = validateRut(formValues.rut)

    if (isEmpty) {
      setError('rut', undefined)
    } else if ((isDirty && !isMinimum) || !isValid) {
      setError('rut', 'rut')
    } else if (isValid) {
      setError('rut', undefined)
      setSuccess({ ...success, rut: true })
    } else {
      setShowToast(false)
    }
  }, [formValues.rut])

  useEffect(() => {
    if (
      formState.errors.rut === undefined &&
      formValues.password?.length > 3 &&
      formValues.rut?.length > 0
    ) {
      setSubmitDisabled(false)
    } else if (
      formState.errors.rut === undefined &&
      formValues.password.length === 0
    ) {
      setSubmitDisabled(true)
    } else if (
      formState.errors.rut === 'credentials' ||
      formState.errors.rut === 'password'
    ) {
      setSubmitDisabled(formValues.password?.length < 4)
    } else if (formState.errors.rut === 'registration') {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }, [formState, formValues])

  return (
    <>
      <LoginModalV2
        {...formState}
        device={getDevice()}
        forgotPasswordHref={forgotPasswordURL}
        formStep={formStep}
        isCheckingRut={checkingRut}
        isLoading={isLoading}
        isMobile={isMobile}
        isOpen={isOpen}
        onClickApple={() => {
          handleClickFirebase('apple')
        }}
        onClickBack={handleClickBack}
        onClickEmail={handleClickOTPMail}
        onClickFinish={handleClickFinishRegistration}
        onClickGoogle={() => {
          handleClickFirebase('google')
        }}
        onClickGuest={handleClickGuest}
        onClickLink={handleClickLink}
        onClickPP={handleOpenPP}
        onClickSMS={handleClickOTPPhone}
        onClickSwitch={handleClickTyC}
        onClickTyC={handleOpenTyC}
        onClose={handleClose}
        onCloseToast={() => {
          setShowToast(false)
        }}
        onInputChange={handleChange}
        onPressReturn={handlePressReturn}
        onResendCode={handleResendOTP}
        onSubmit={handleSubmitLogin}
        onSubmitOTP={handleSubmitOTP}
        passwordDisabled={false}
        showPassword={showPassword}
        showToast={showToast}
        submitDisabled={submitDisabled}
        success={success}
        values={formValues}
      />
      <InnerModal
        contentType={innerModalContent}
        isMobile={isMobile}
        isOpen={showInnerModal}
        onClose={handleCloseInnerModal}
        richTextData={
          innerModalContent === 'tyc'
            ? TyCndPPData.tycRichtextData
            : TyCndPPData.ppRichtextData
        }
      />
    </>
  )
}
