import { useState } from 'react'
import { ReactQueryDevtools } from '@smu-chile/pkg-unimarc-hooks/reactQuery/devtools'
import {
  Hydrate,
  QueryClient,
  QueryClientProvider
} from '@smu-chile/pkg-unimarc-hooks/reactQuery'
import Script from 'next/script'
import { Layout } from 'components/Layout'
import '../styles/globals.css'
import '@smu-chile/pkg-unimarc-components/assets/styles/tokens.css'
import '@smu-chile/pkg-unimarc-components/assets/styles/globals.css'
import ErrorBoundary from 'components/ErrorBoundary'
import CurtainWrapper from 'components/CurtainWrapper'
import { FFContextProvider } from '@smu-chile/pkg-unimarc-hooks'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { AppPropsWithLayout } from 'shared/interfaces/Layout'

function MyApp({
  Component,
  pageProps,
  isInvalidUrlForReCaptcha
}: AppPropsWithLayout): React.ReactElement {
  const harnessReactTarget = {
    name: 'ReactClientSDK',
    identifier: 'reactclientsdk'
  }
  const [queryClient] = useState(() => {
    return new QueryClient()
  })

  return (
    <>
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}&libraries=places&types`}
        strategy='beforeInteractive'
      />

      <Script
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', 'GTM-TSJPRBR');
        `
        }}
        strategy='afterInteractive'
      />

      {!isInvalidUrlForReCaptcha ? (
        <GoogleReCaptchaProvider
          language='es-419'
          reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_V3_KEY}
          scriptProps={{
            async: false,
            defer: true
          }}
          useEnterprise={true}
        >
          <QueryClientProvider client={queryClient}>
            <FFContextProvider
              apiKey={process.env.NEXT_PUBLIC_HARNESS_FEATURE_FLAG}
              async
              target={harnessReactTarget}
            >
              <Hydrate state={pageProps?.dehydratedState}>
                <CurtainWrapper>
                  <Layout>
                    <ErrorBoundary>
                      <Component {...pageProps} />
                    </ErrorBoundary>
                  </Layout>
                  <ReactQueryDevtools />
                </CurtainWrapper>
              </Hydrate>
            </FFContextProvider>
          </QueryClientProvider>
        </GoogleReCaptchaProvider>
      ) : (
        <QueryClientProvider client={queryClient}>
          <FFContextProvider
            apiKey={process.env.NEXT_PUBLIC_HARNESS_FEATURE_FLAG}
            async
            target={harnessReactTarget}
          >
            <Hydrate state={pageProps?.dehydratedState}>
              <CurtainWrapper>
                <Layout>
                  <ErrorBoundary>
                    <Component {...pageProps} />
                  </ErrorBoundary>
                </Layout>
                <ReactQueryDevtools />
              </CurtainWrapper>
            </Hydrate>
          </FFContextProvider>
        </QueryClientProvider>
      )}
    </>
  )
}

MyApp.getInitialProps = ({ ctx }) => {
  const isInvalidUrlForReCaptcha = ctx?.req?.url?.includes('source=APP')
  return {
    isInvalidUrlForReCaptcha
  }
}

export default MyApp
